import React from 'react';
import Box from 'ReusableComponents/Box';
import defaultProfile from 'assets/default_profile.png';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'store/auth/actions';
import { useMediaQuery } from '@material-ui/core';
import { persistor } from 'store';
import { truncateFullName, useModal } from 'helpers';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from 'ReusableComponents/PopUp';
import StyledLink from 'ReusableComponents/Link';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';
import './style.css';
import Text from 'ReusableComponents/Text';

function UserDisplayPicture() {
  const MQ450 = useMediaQuery('(max-width:450px)');
  const history = useHistory();
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();
  const displayUserPopup = useModal();

  const logOut = () => {
    dispatch(signOut());
    persistor.purge();
    history.push('/');
  };
  function Menu() {
    const fullName = `${user.firstName} ${user.lastName}`;
    return (
      <Modal
        className="user-display-menu"
        isShown={displayUserPopup.isModalShown}
        label="Edit image"
        onHide={displayUserPopup.hideModal}
      >
        <Box display="flex" gap="1rem">
          <Box
            borderRadius="6px"
            h={MQ450 ? '2.813rem' : '3.75rem'}
            overflow="hidden"
            w={MQ450 ? '2.813rem' : '3.75rem'}
          >
            <img
              alt=""
              height="100%"
              src={user.image || defaultProfile}
              width="100%"
            />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" w="max-content">
            <Box
              color={DESIGN_CONFIG.color.text.text500}
              fontSize="1.125rem"
              fontWeight="600"
            >
              {truncateFullName(fullName)}
            </Box>
            <Box
              color={DESIGN_CONFIG.color.text.text100}
              fontSize="0.875rem"
              fontWeight="400"
            >
              @
              {user.username}
            </Box>
          </Box>
        </Box>
        <Box
          bb={`1px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}
          bt={`1px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}
          marginTop="1rem"
          paddingBottom=".75rem"
        >
          <Text
            fontSize=".875rem"
            fontWeight={600}
            margin=".75rem 0"
            textColor={DESIGN_CONFIG.color.text.text500}
          >
            {STRING_CONFIG.inPage.common.txtPersonal}
          </Text>
          <StyledLink
            color={DESIGN_CONFIG.color.text.text100}
            display="block"
            fontSize="0.813rem"
            fontWeight={400}
            marginTop=".75rem"
            to="/user-profile"
          >
            {STRING_CONFIG.inPage.common.lnkEditProfile}
          </StyledLink>
          <StyledLink
            color={DESIGN_CONFIG.color.text.text100}
            display="block"
            fontSize="0.813rem"
            fontWeight={400}
            marginTop=".75rem"
            to="/my-account"
          >
            {STRING_CONFIG.inPage.myAccount.txtMyAccount}
          </StyledLink>
        </Box>
        <Text
          fontSize=".875rem"
          fontWeight={600}
          margin=".75rem 0"
          textColor={DESIGN_CONFIG.color.text.text500}
        >
          {STRING_CONFIG.inPage.common.txtSupport}
        </Text>
        <StyledLink
          color={DESIGN_CONFIG.color.text.text100}
          display="block"
          fontSize="0.813rem"
          fontWeight={400}
          to="/help"
        >
          {STRING_CONFIG.inPage.common.lnkHelp}
        </StyledLink>
        <button
          type="button"
          style={{
            background: 'transparent',
            border: 'none',
            color: `${DESIGN_CONFIG.color.text.text100}`,
            cursor: 'pointer',
            fontSize: '.75rem',
            fontWeight: 400,
            marginTop: '.75rem',
            padding: '0',
          }}
          onClick={logOut}
        >
          {STRING_CONFIG.inPage.common.lnkSignOut}
        </button>
      </Modal>
    );
  }
  return (
    <>
      <button
        aria-label="display user"
        id="display-user-button"
        onClick={displayUserPopup.showModal}
        ref={displayUserPopup.openModalButtonRef}
        type="button"
      >
        <LazyLoadImage
          alt=""
          effect="blur"
          src={user.image || defaultProfile}
          style={{
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            objectFit: 'contain',
          }}
        />
      </button>
      <Menu />
    </>

  );
}

export default UserDisplayPicture;
