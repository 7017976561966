export const ENDPOINTS = {
  add_answer: (QUESTION_ID) => `/api/v1/product/question/${QUESTION_ID}/answers`,
  add_comment: (REVIEW_ID) => `/api/v1/product/review/${REVIEW_ID}/comment`,
  add_commentOnArticle: (ARTICLE_ID) => `api/v1/articles/${ARTICLE_ID}/add-comment`,
  add_question: (PRODUCT_ID) => `/api/v1/product/${PRODUCT_ID}/questions`,
  add_reaction_on_answer: (ANSWER_ID, REACTION_VALUE) => `/api/v1/product/question/answer/${ANSWER_ID}/react/${REACTION_VALUE}`,
  add_reaction_on_article: (ARTICLE_ID, REACTION_VALUE) => `/api/v1/articles/${ARTICLE_ID}/react/${REACTION_VALUE}`,
  add_reaction_on_comment: (COMMENT_ID, REACTION_VALUE) => `/api/v1/product/review/comment/${COMMENT_ID}/react?reaction=${REACTION_VALUE}`,
  add_reaction_on_product: (PRODUCT_ID, REACTION_VALUE) => `/api/v1/product/${PRODUCT_ID}/react/${REACTION_VALUE}`,
  add_reaction_on_question: (QUESTION_ID, REACTION_VALUE) => `/api/v1/product/question/${QUESTION_ID}/react/${REACTION_VALUE}`,
  add_reaction_on_reply: (REPLY_ID) => `/api/v1/product/review/comment/reply/${REPLY_ID}/react`,
  add_reaction_on_review: (REVIEW_ID) => `/api/v1/product/review/${REVIEW_ID}/react`,
  add_reply: (COMMENT_ID) => `/api/v1/product/review/comment/${COMMENT_ID}/reply`,
  add_review: (PRODUCT_ID) => `/api/v1/product/${PRODUCT_ID}/review`,
  delete_user_account: '/api/v1/user/delete',
  add_user_campaign: '/api/v1/submit-campaign',
  change_password: '/api/v1/user/password',
  create_order: '/api/v1/initiate-transaction',
  delete_answer_by_id: (ANSWER_ID) => `/api/v1/product/answer/${ANSWER_ID}`,
  delete_comment_by_id: (COMMENT_ID) => `/api/v1/product/review/comment/${COMMENT_ID}`,
  delete_question_by_id: (QUESTION_ID) => `/api/v1/product/question/${QUESTION_ID}`,
  delete_reply_by_id: (REPLY_ID) => `/api/v1/product/review/comment/reply/${REPLY_ID}`,
  delete_review_by_id: (REVIEW_ID) => `/api/v1/product/review/${REVIEW_ID}`,
  delete_user_image: '/api/v1/user/delete-user-image',
  email_availability: '/api/v1/user/check-email-availability',
  follow_user: '/api/v1/user/follow',
  get_answers_by_questionid: (PRODUCT_ID, QUESTION_ID, PAGE, SIZE) => `/api/v1/product/${PRODUCT_ID}/question/${QUESTION_ID}/answers?page=${PAGE}&size=${SIZE}`,
  get_brands_in_category: (CATEGORY_ID) => `/api/v1/product/${CATEGORY_ID}/get-brands?getPrimeAttributeRanges=false`,
  get_browse_data: (CATEGORY_ID, releasedWithinMonths) => `/api/v1/product/${CATEGORY_ID}/get-brands?getPrimeAttributeRanges=true&releasedWithinMonths=${releasedWithinMonths}`,
  get_comments_by_reviewid: (REVIEW_ID, PAGE, SIZE) => `/api/v1/product/review/${REVIEW_ID}/comments?page=${PAGE}&size=${SIZE}`,
  google_oauth: '/api/v1/auth/google',
  get_product_information_by_productid: (PRODUCT_ID) => `/api/v1/product/${PRODUCT_ID}`,
  get_product_in_brand: (BRAND_ID) => `/api/v1/product/${BRAND_ID}/get-products`,
  get_product_liked_for_loggedin_user: (PAGE, SIZE) => `/api/v1/user/products-upvoted?page=${PAGE}&size=${SIZE}`,
  get_questions_by_productid: (PRODUCT_ID, PAGE, SIZE) => `/api/v1/product/${PRODUCT_ID}/questions?page=${PAGE}&size=${SIZE}`,
  get_replies_by_commentid: (COMMENT_ID, PAGE, SIZE) => `/api/v1/product/review/comment/${COMMENT_ID}/replies?page=${PAGE}&size=${SIZE}`,
  get_reviews_by_productid: (PRODUCT_ID, PAGE, SIZE) => `/api/v1/product/${PRODUCT_ID}/reviews?page=${PAGE}&size=${SIZE}`,
  get_reviews_for_loggedin_user: (PAGE, SIZE) => `/api/v1/user/reviews?page=${PAGE}&size=${SIZE}`,
  get_category_product_list_homepage: (CATEGORY_ID) => `/api/v1/home-page/category/${CATEGORY_ID}`,
  get_most_liked_product: (PAGE, SIZE, CATEGORY_ID) => `/api/v1/most-reviewed-products/category/${CATEGORY_ID}?page=${PAGE}&size=${SIZE}`,
  get_most_reviewd_product: (PAGE, SIZE, CATEGORY_ID) => `/api/v1/most-reviewed-products/category/${CATEGORY_ID}?page=${PAGE}&size=${SIZE}`,
  get_most_viewed_product: (PAGE, SIZE, CATEGORY_ID) => `/api/v1/most-reviewed-products/category/${CATEGORY_ID}?page=${PAGE}&size=${SIZE}`,
  get_profile_data_by_username: (PAGE, SIZE, USERNAME) => `/api/v1/user/${USERNAME}/get-profile?page=${PAGE}&size=${SIZE}`,
  get_user_ip: 'https://api.ipify.org/?format=json',
  get_transaction_detail: '/api/v1/get-transactions',
  get_article: (ARTICLE_ID) => `/api/v1/get-article/${ARTICLE_ID}`,
  get_comments_for_article: (PAGE, SIZE, ARTICLE_ID) => `/api/v1/articles/${ARTICLE_ID}/comments?page=${PAGE}&size=${SIZE}`,
  initiate_forgot_password: '/api/v1/auth/password/reset',
  platform_search: (QUERY) => `/api/v1/search?searchQuery=${QUERY}`,
  platform_search_results: (PAGE, SIZE, QUERY) => `/api/v1/search?searchQuery=${QUERY}&page=${PAGE}&size=${SIZE}`,
  report_reviw: '/api/v1/product/review/report',
  report_user: '/api/v1/user/report',
  sign_in: '/api/v1/auth/sign-in',
  sign_up: '/api/v1/user',
  username_availability: (USERNAME) => `/api/v1/user/check-username-availability/${USERNAME}`,
  update_user_details: '/api/v1/user/update',
  update_oauth_more_details: '/api/v1/user/oauth-credentials',
  upload_user_image: '/api/v1/user/upload-user-image',
  verify_email: (TOKEN) => `/api/v1/auth/email-verify/${TOKEN}`,
};
