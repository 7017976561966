import React, { useEffect, useState } from 'react';
import { STRING_CONFIG, SETTING_CONFIG } from 'utils';

export function getYearsFromMonths(months) {
  if (months < 0) {
    return 'Invalid: Months value';
  }
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  if (years === 0 && remainingMonths === 0) {
    return 'Invalid: Months value';
  }
  return `${years > 0 ? `${years} ${years === 1 ? 'year' : 'years'}` : ''}${remainingMonths ? `${years > 0 ? ' and ' : ''}${remainingMonths} months` : ''}`;
}

export const computeAttributes = (attributes, attributeNames, categoryId) => {
  const sections = [
    Object.keys(STRING_CONFIG.entityCaption.category[categoryId].product)[0],
    Object.keys(STRING_CONFIG.entityCaption.category[categoryId].product)[2],
    Object.keys(STRING_CONFIG.entityCaption.category[categoryId].product)[1],
    Object.keys(STRING_CONFIG.entityCaption.category[categoryId].product)[3],
  ];
  const ranges = [
    [1, 6],
    [7, 12],
    [13, 18],
    [19, 24],
  ];

  const attributeRanges = sections.reduce((acc, section, index) => {
    acc[section] = ranges[index];
    return acc;
  }, {});

  const similarAttributes = {};
  const differentAttributes = {};

  sections.forEach((section) => {
    similarAttributes[section] = {};
    differentAttributes[section] = {};

    const [start, end] = attributeRanges[section];

    for (let attributeCnt = start; attributeCnt <= end; attributeCnt++) {
      const attributeKey = `attribute_${attributeCnt}`;
      const descriptiveKey = attributeNames[section][`attr${attributeCnt}`];

      if (!descriptiveKey) continue;

      const ProductOneAttributeVal = attributes.compareProductOneAttributes?.[section][attributeKey];
      const ProductTwoAttributeVal = attributes.compareProductTwoAttributes?.[section][attributeKey];
      const ProductThreeAttributeVal = attributes.compareProductThreeAttributes?.[section][attributeKey];

      const attributeValues = [ProductOneAttributeVal, ProductTwoAttributeVal, ProductThreeAttributeVal].filter((val) => val !== undefined);
      if (attributeValues.length === 0) continue;

      const isSimilar = attributeValues.every((val) => val === attributeValues[0]);

      if (isSimilar) {
        similarAttributes[section][descriptiveKey] = {};
        if (ProductOneAttributeVal !== undefined) similarAttributes[section][descriptiveKey].productOne = ProductOneAttributeVal;
        if (ProductTwoAttributeVal !== undefined) similarAttributes[section][descriptiveKey].productTwo = ProductTwoAttributeVal;
        if (ProductThreeAttributeVal !== undefined) similarAttributes[section][descriptiveKey].productThree = ProductThreeAttributeVal;
      } else {
        differentAttributes[section][descriptiveKey] = {};
        if (ProductOneAttributeVal !== undefined) differentAttributes[section][descriptiveKey].productOne = ProductOneAttributeVal;
        if (ProductTwoAttributeVal !== undefined) differentAttributes[section][descriptiveKey].productTwo = ProductTwoAttributeVal;
        if (ProductThreeAttributeVal !== undefined) differentAttributes[section][descriptiveKey].productThree = ProductThreeAttributeVal;
      }
    }
  });
  return { similarAttributes, differentAttributes };
};

export function scaleAttributeFromJSON(attributeObject, categoryId) {
  try {
    const attributeValue = attributeObject;
    const { value } = attributeValue;
    const phyQtyId = attributeValue['phy-qty-id'];

    if (!phyQtyId || value == null) return 0;

    const { scaleFactor } = SETTING_CONFIG.unitConversion[categoryId][phyQtyId];

    if (typeof value === 'number') {
      return value * scaleFactor;
    }

    if (typeof value === 'object') {
      const firstKey = Object.keys(value)[0];
      if (firstKey && typeof value[firstKey] === 'number') {
        return value[firstKey] * scaleFactor;
      }
    }
    return 0;
  } catch (error) {
    return null;
  }
}

function scaleAttribute(attributeValue, scaleFactor = 1, unitSymbol = '') {
  const formatValue = (val) => {
    const scaled = val * scaleFactor;
    return scaled % 1 === 0
      ? `${scaled}${unitSymbol}`
      : `${scaled.toFixed(2)}${unitSymbol}`;
  };
  if (typeof attributeValue === 'number') {
    return formatValue(attributeValue);
  } if (typeof attributeValue === 'object' && attributeValue !== null) {
    return Object.values(attributeValue)
      .map((val) => (typeof val === 'number' ? formatValue(val) : val))
      .filter((val) => val !== '')
      .join(', ');
  }
  return attributeValue;
}

export function SimplifyAttributes(attributes, categoryId) {
  const parsedAttributes = typeof attributes === 'string' ? JSON.parse(attributes) : attributes;

  function processAttributes(obj, categoryId) {
    const result = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        try {
          const parsedValue = JSON.parse(obj[key]);
          if (parsedValue && typeof parsedValue === 'object') {
            result[key] = parsedValue;
          } else {
            result[key] = obj[key];
          }
        } catch (e) {
          result[key] = obj[key];
        }
      } else if (obj[key] && typeof obj[key] === 'object') {
        if ('value' in obj[key]) {
          const { value, 'phy-qty-id': phyQtyId } = obj[key];
          if (value === '' || value === null || value === undefined) {
            result[key] = 'N/A';
            continue;
          }
          let scaleFactor = 1; let
            unitSymbol = '';
          if (phyQtyId && SETTING_CONFIG.unitConversion[categoryId][phyQtyId]) {
            scaleFactor = SETTING_CONFIG.unitConversion[categoryId][phyQtyId].scaleFactor;
            unitSymbol = SETTING_CONFIG.unitConversion[categoryId][phyQtyId].unitSymbol;
          }
          result[key] = scaleAttribute(value, scaleFactor, unitSymbol);
        } else {
          result[key] = processAttributes(obj[key], categoryId);
        }
      } else if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        result[key] = 'N/A';
      } else {
        result[key] = obj[key];
      }
    }
    return result;
  }
  return processAttributes(parsedAttributes, categoryId);
}

export function capitalizeWord(str) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export const camelize = (str) => str
  .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
  .replace(/\s+/g, '');

export const notSpecialCharacter = (string) => /[^a-zA-Z0-9]+/g.test(string);

export function getTextForValue(value) {
  if (value >= 0 && value < 2) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.one}`;
  } if (value >= 2 && value < 3) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.two}`;
  } if (value >= 3 && value < 4) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.three}`;
  } if (value >= 4 && value < 5) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.four}`;
  } if (value === 5) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.five}`;
  }
  return 'unknown';
}

export const truncateFullName = (fullName) => {
  if (fullName.length <= 16) {
    return fullName;
  }
  return `${fullName.slice(0, 12)}...`;
};

export function formatNumberAsRepresentation(value) {
  const number = Number(value);
  if (number < 100) {
    return String(number);
  } if (number < 1000) {
    return '100+';
  } if (number < 1000000) {
    return `${(number / 1000).toFixed(0)}K+`;
  } if (number > 1000000) {
    return '999K+';
  }
}

export function formatDate(dateString) {
  const options = {
    year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
  };
  const date = new Date(dateString);
  return date.toLocaleString('en-IN', options);
}

export function getAdsDurationString(amount) {
  const daysMapping = {
    5: '1 day',
    25: '7 days',
    65: '30 days',
    450: '1 year',
    999: 'forever',
  };
  return `${daysMapping[amount] || 'unknown duration'}`;
}

export default function useClickOutside(ref, fun) {
  useEffect(() => {
    const listener = (e) => {
      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }
      fun();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref]);
}

export function useMedia(query) {
  const [matches, setMatches] = useState(() => window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    const updateMatches = (event) => {
      setMatches(event.matches);
    };

    updateMatches(mediaQuery);

    const mediaQueryEventListener = (event) => updateMatches(event);
    mediaQuery.addEventListener('change', mediaQueryEventListener);

    return () => {
      mediaQuery.removeEventListener('change', mediaQueryEventListener);
    };
  }, [query]);

  return matches;
}

export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const onPopState = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, []);

  return null;
};

export const useModal = () => {
  const openModalButtonRef = React.useRef(null);
  const [isModalShown, setIsModalShown] = React.useState(false);

  const showModal = () => {
    setIsModalShown(true);
  };

  const hideModal = () => {
    setIsModalShown(false);
    openModalButtonRef.current.focus();
  };

  return {
    openModalButtonRef,
    isModalShown,
    showModal,
    hideModal,
  };
};
