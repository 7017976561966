import styled from 'styled-components';
import { DESIGN_CONFIG } from 'utils';

export const ButtonStyled = styled.button`
  align-items: ${({ alignItems }) => alignItems};
  align-self: ${({ alignSelf }) => alignSelf};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  background-color: ${({ background = 'white' }) => background};
  border-left: ${({ bl }) => bl};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  border-right: ${({ br }) => br};
  bottom: ${({ bottom }) => bottom};
  color: ${({ textColor }) => textColor};
  cursor: ${({ cursor }) => cursor};
  display: ${({ display }) => display};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  gap: ${({ gap }) => gap};
  height: ${({ h }) => h};
  justify-content: ${({ justifyContent }) => justifyContent};
  left: ${({ left }) => left};
  line-height: ${({ lineHeight }) => lineHeight};
  line-spacing: ${({ letterSpacing }) => letterSpacing};
  margin: ${({ m }) => m};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-top: ${({ marginTop }) => marginTop};
  outline: ${({ outline }) => outline};
  padding: ${({ padding }) => padding};
  position: ${({ position }) => position};
  transition: ${({ transition }) => transition};
  white-space: ${({ whiteSpace }) => whiteSpace};
  width: ${({ w }) => w};
  &:hover,
  &:focus {
    ${({ disabled, background }) => !disabled && `
      background: ${background === DESIGN_CONFIG.color.primary.primaryMain ? DESIGN_CONFIG.color.common.primaryHover : background};
      cursor: pointer;
    `}
  }
  &:focus-visible {
    outline: 2px solid ${DESIGN_CONFIG.color.primary.primaryMain};
    outline-offset: 2px;
  }

  ${({ disabled }) => disabled
    && `
    pointer-events: none;
    background-color: ${DESIGN_CONFIG.color.common.primaryDisable};
    user-select:none;
  `}

  ${({ disabled, background }) => disabled
    && background
    && `
    background: ${background === DESIGN_CONFIG.color.primary.primaryMain ? DESIGN_CONFIG.color.common.primaryDisable : background};
  `}
`;
